<template>
  <div class="errPage-container">
    <el-row>
      <h1 style="text-align: center;">Tutoriels pour l'application mobile SCIAGRI</h1>
    </el-row>
    <el-row>
      <div class="video-item">
        <el-col v-for="tutorial in tutorials" :key="tutorial.id" :span="8">

          <el-card :body-style="{ justifyContent: 'center' }">
            <video
              :id="tutorial.id"
              ref="videoPlayer"
              class="video-js vjs-default-skin"
              controls
              preload="auto"
              :poster="tutorial.thumbnailUrl"
            >
              <!--  -->
              <source :src="tutorial.videoUrl" type="video/mp4">
            </video>
            <div style="padding: 14px;">
              <div class="clearfix">
                <time class="title">{{ tutorial.title }}</time>
              </div>
            </div>
          </el-card>

          <el-col /></el-col></div>
    </el-row>
  </div>
</template>

<script>
import 'video.js';
import 'video.js/dist/video-js.css';
import Resource from '@/api/resource';

const tutoVideosResource = new Resource('tuto-videos');

export default {
  data() {
    return {
      query: {
        page: 1,
        limit: 100,
        keyword: '',
      },
      tutorials: [],
    };
  },
  created(){
    this.fetchTutoVideos();
  },
  mounted() {
    // this.initializeVideoPlayers();
  },
  methods: {
    async fetchTutoVideos() {
      const data = await tutoVideosResource.list(this.query);
      if (data.success){
        this.tutorials = data.data;
      } else {
        this.$message({
          type: 'danger',
          message: data.message,
        });
      }
    },
    /* initializeVideoPlayers() {
      this.$nextTick(() => {
        const videoPlayers = this.tutorials;

        videoPlayers.forEach((player) => {
          videojs(player);
        });
      });
    }, */
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .video-js{
    width: 100%;
    height: auto;
  }
  .title{
    text-align: center;
  }
  .clearfix{
    text-align: center;
  }
</style>
