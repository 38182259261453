<template>
  <div class="app-container">
    <div class="page-title">
      <h2>Demande de suppression de compte</h2>
    </div>
    <el-row :gutter="40" class="panel-group">
      <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
        <div class="card-panel">
          <div class="card-panel-icon-wrapper icon-people">
            Veuillez remplir ce formulaire pour envoyer la demande de suppression de compte
          </div>
          <!-- <div class="card-panel-description">
            <div class="card-panel-text">
              Veuillez remplir ce formulaire pour envoyer la demande de suppression de compte
            </div>
          </div> -->
        </div>
      </el-col>
    </el-row>
    <el-card class="box-card filter-card just-for-admin">
      <div class="content-inputs">
        <el-form ref="gradeControleurForm" :rules="rules" :model="currentDemandeSuppression" label-position="left">
          <el-form-item label="Nom" prop="nom">
            <el-input v-model="currentDemandeSuppression.nom" />
          </el-form-item>
          <el-form-item label="Prénom" prop="prenom">
            <el-input v-model="currentDemandeSuppression.prenom" />
          </el-form-item>
          <el-form-item label="Email" prop="email">
            <el-input v-model="currentDemandeSuppression.email" type="email" />
          </el-form-item>
          <el-form-item label="Mot de passe" prop="password">
            <el-input v-model="currentDemandeSuppression.password" type="password" />
          </el-form-item>
          <el-form-item label="Motif de la demande" prop="motif">
            <el-input v-model="currentDemandeSuppression.motif" type="textarea" />
          </el-form-item>
        </el-form>
        <div style="text-align:right;">
          <el-button plain type="danger" @click="resetDemande()">
            {{ $t('permission.cancel') }}
          </el-button>
          <el-button type="primary" :loading="demandeCreating" @click="createDemande()">
            {{ demandeCreating ? $t('general.saving') : $t('general.save') }}
          </el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination'; // Pagination
// import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
// import moment from 'moment';
// import CountTo from 'vue-count-to';
// const gradeControleurResource = new Resource('grade_controleurs');
// const bulkGradeControleurResource = new Resource('grade_controleurs_import');
// import UploadExcelComponent from '@/components/UploadExcel/index.vue';
export default {
  name: 'GradeControleursList',
  // components: {  },
  // components: { CountTo },
  directives: { waves, permission, role },
  data() {
    return {
      tableData: [],
      tableHeader: [],
      drawer: false,
      list: null,
      total: 0,
      loading: true,
      downloading: false,
      activeList: 'disponibles',
      demandeCreating: false,
      editing: false,
      currentDemandeSuppression: {},
      query: {
        page: 1,
        limit: 100,
        keyword: '',
      },
      dialogcreateDemande: false,
      currentDemandeSuppressionId: 0,
      rules: {
        nom: [{ required: true, message: this.$t('gradeControleur.NomRequired'), trigger: 'blur' }],
      },
      fileList: [],
    };
  },
  computed: {

  },
  methods: {
    checkPermission,
    checkRole,
    createDemande() {
      this.$swal({
        title: 'Demande envoyé',
        text: 'Votre demande de suppression de compte soumise avec succès',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
      });
    },
    resetDemande() {
      this.currentDemandeSuppression = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-group {
  margin-top: 18px;

  .card-panel-col {
    margin-bottom: 32px;
  }

  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }

      .icon-people {
        background: #40c9c6;
      }

      .icon-message {
        background: #36a3f7;
      }

      .icon-money {
        background: #f4516c;
      }

      .icon-shopping {
        background: #34bfa3
      }
    }

    .icon-people {
      color: #40c9c6;
    }

    .icon-message {
      color: #36a3f7;
    }

    .icon-money {
      color: #f4516c;
    }

    .icon-shopping {
      color: #34bfa3
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}

@media (max-width:550px) {
  .card-panel-description {
    display: none;
  }

  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
